import { useQuery } from '@abyss/web/hooks/useQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useContext } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { errorCodeRouteMap } from './../../common/Constants';
import { convertObjectToUrlParams } from '../../common/Utils';
import { PortalContext } from '../../context/PortalContext';
import { useAnalyticsStore } from '../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../store/useAnalyticsStore/analyticsStore';
import { useErrorStore } from '../../store/useErrorStore';
import { ErrorStore } from '../../store/useErrorStore/errorStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { getCurrentMember } from '../../utils/user.utils';
import { useTranslationLog } from '../useTranslationLog/useTranslationLog';
import { getGraphqlUrl } from './helpers';

const getCookieValue = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
  return null;
}

const getHeaders = (currentMember, portalData, typeaheadStore) => {
  let headerOptions;

  // Get and set lob and population header
  let lob = typeaheadStore?.lob || currentMember?.lineOfBusiness;
  lob = lob ?? 'UNKNOWN';
  lob = lob.replace(/&/g, 'n').toLowerCase();
  const population = currentMember?.population?.toLowerCase() || lob;
  headerOptions = { lob, population };

  // Get and set portalName header
  if (portalData?.portalName) {
    headerOptions = { ...headerOptions, portal: portalData.portalName };
  }

  //Adding blue/green to query header based on override or LaunchDarkly Flag

  const host = window.location.hostname;

  if(host.includes(".uhc.com")) {
    const bgOverrideFlag = getCookieValue("bgTestingEnv");

    if (bgOverrideFlag) {
      headerOptions = {...headerOptions, bgEnv: bgOverrideFlag};
    } else {
      const bgParcelsCookie = getCookieValue('bgParcelsCookie');
      headerOptions = {...headerOptions, bgEnv: bgParcelsCookie};
    }
  }

  return headerOptions;
};

export const useCustomQuery = (
  query,
  queryOptions
): [data: any, getData: (params: any) => any] => {
  const { navigate } = useRouter();
  const { portalData } = useContext(PortalContext);

  const { linkName, originLinkNameForAnalytics } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      linkName: state.analyticsState.linkName,
      originLinkNameForAnalytics:
        state.analyticsState.originLinkNameForAnalytics,
    }))
  );

  const { setErrorStore } = useErrorStore(
    useShallow((state: ErrorStore) => ({
      setErrorStore: state.setErrorStore,
    }))
  );

  const { typeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      typeaheadSearchStore: state.typeaheadSearchStore,
    }))
  );

  const currentMember = getCurrentMember();
  const headerOptions = getHeaders(
    currentMember,
    portalData,
    typeaheadSearchStore
  );

  const url = getGraphqlUrl(portalData, queryOptions);

  const translationLogger = useTranslationLog({}, headerOptions, url);

  const [queryResult, queryAction] = useQuery(query, {
    ...{ ...queryOptions, url },
    headers: { ...queryOptions.headers, ...headerOptions },
    onCompleted: (result) => {
      if (queryOptions.onCompleted) {
        queryOptions.onCompleted(result);
      }

      if (result?.data?.missingPSXCodeTranslations?.length) {
        translationLogger({
          variables: {
            missedTranslations: result.data.missingPSXCodeTranslations,
          },
        });
      }
    },
    onError: (error) => {
      const gQLError = error?.response?.data?.errors?.[0]?.extensions;
      const regEx = /4\d{2}|5\d{2}/;
      if (
        regEx.test(gQLError?.status) &&
        gQLError?.pageType !== 'Custom' &&
        gQLError?.pageType !== 'Ignore' &&
        error?.operationName !== 'ProviderSearch'
      ) {
        const errorStoreValues = {
          message: gQLError?.message,
          code: gQLError?.status,
          source: error?.operationName,
          uiSection: linkName || originLinkNameForAnalytics,
        };
        setErrorStore(errorStoreValues);
        // @ts-ignore
        const urlParams = convertObjectToUrlParams(
          {},
          {
            ...errorStoreValues,
          }
        );

        navigate(
          `${
            errorCodeRouteMap[gQLError?.status] || errorCodeRouteMap['4xx']
          }${urlParams}`
        );
      }
      if (queryOptions.onError && typeof queryOptions.onError === 'function') {
        queryOptions.onError(gQLError);
      }
    },
  });
  return [queryResult, queryAction];
};
