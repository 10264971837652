import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { FeatureFlags } from '../../../common/ConstantsFeatureFlags';
import { getFeatureFlag } from '../../../common/Utils';
import { CountySearchContext } from '../../../context/CountySearchContext';
import { getLanguage } from '../../../frontends/ProviderSearch/context/Internationalization/helpers';
import { useAutoCompleteQuery } from '../../../hooks/useAutoCompleteQuery';
import { useCoverageType } from '../../../hooks/useCoverageType';
import { useEventCapture } from '../../../hooks/useEventCapture';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { useLagoon } from '../../../hooks/useLagoon';
import { useLocation } from '../../../hooks/useLocation';
import { getExcludeNatAncForNetworkIds } from '../../../hooks/useProviderSearch/useProviderSearch';
import { useTypeaheadPseudoRollupTable } from '../../../hooks/useTypeaheadPseudoRollup/useTypeaheadPseudoRollup';
import { useAnalyticsStore } from '../../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../../store/useChipStore';
import { ChipState } from '../../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../../store/useDetailsStore';
import { DetailsStore } from '../../../store/useDetailsStore/detailsStore';
import { usePCPStore } from '../../../store/usePCPStore';
import { PCPSearchStore } from '../../../store/usePCPStore/pcpStore';
import { useTypeaheadStore } from '../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../store/useTypeaheadStore/typeaheadStore';
import { returnSuppressFlag } from '../../../utils/featureSuppress';
import { getSuppressFacilityFlags } from '../../../utils/providerDetails.utils';
import { excludeDisabledResults } from '../../../utils/typeahead.utils';
import {
  getClaimOrEligibilitySystemTypeCode,
  getCoverageTypes,
  getCurrentMember,
  getDomainForAutoComplete,
  getMemberCoverage,
  getMemberShipCategory,
} from '../../../utils/user.utils';
import { adobeImpressionTrackEvent } from '../../AdobeTagging/adobeImpressionTrackEvent';
import {
  Constants,
  MemberCoverageTypeToUICoverageTypeConversion,
  OPTUM_CORRELATION_ID_HEADER,
} from '../../Constants';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { mobileOnly } from '../../ConstantsStyles';
import { isCounty } from '../../SnackCardContainer/utils';
import {
  convertTypeaheadProviderIdAndType,
  getSearchLocation,
} from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { getIntValue } from '../../Utils/getIntValue';
import { Breadcrumb } from '../Breadcrumb';
import { KeywordSearchDropdown } from './KeywordSearchDropdown';
import { LocationDropdown } from './LocationDropdown';
import { ChangeLocationForm } from './LocationDropdown/ChangeLocation';
import { ChangeLocationDrawer } from './LocationDropdown/LocationDropdown.styled';
import { LocationInput } from './LocationInput';
import { MobileSetLocation } from './MobileSetLocation';
import { DarkeningOverlay, InputBox, PositioningBox } from './SearchBar.styled';
import { SearchPlacement } from './SearchPlacement';
import {
  getGeoLocationFromStorage,
  getTypeAheadResultTrackingData,
  handleKeywordSuggestionKeyDownHelper,
  setGeoLocationToStorage,
  setTypeAheadResults,
} from './utils';

enum UesSuggestions {
  EAP = 'EAP',
}

enum PseudoRollupCodeSearchDescription {
  FSF = 'Freestanding facility',
  PLN = 'preferred lab network (PLN)',
  OX_PLN = 'Oxford preferred lab network',
  DDP_RAD = 'Designated Diagnostic Provider: Imaging',
  DDP_LAB = 'Designated Diagnostic Provider: Lab',
}

type Props = {
  choosePCPHeader?: boolean;
  memberLocation?: string;
  showSearchInputBackButton?: boolean;
  breadcrumbs?: any[];
  chipsToTabsEnabled: boolean;
  isMixedResultsPage: boolean;
  showResultsPageForMap: boolean;
};

export const SearchBar = ({
  choosePCPHeader,
  memberLocation = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.MEMBER_LOCATION
  ) ?? '',
  showSearchInputBackButton = false,
  breadcrumbs = [],
  chipsToTabsEnabled,
  isMixedResultsPage,
  showResultsPageForMap,
}: Props) => {
  let network: string;
  const language = getLanguage()?.code || 'en';
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const keywordSearchInputId = useId();
  const currentMember = getCurrentMember();
  const coverageType = useCoverageType();
  const mobileScreen = useMediaQuery(mobileOnly);
  const data = useLagoon('ui-messaging')();
  const typeaheadParams = useLagoon(Constants.LAGOON_TABLE.TYPEAHEAD_PARAMS)();
  const [, getLocation] = useLocation({});
  const isGuestUser = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.IS_GUEST
  );

  const limitSpl = getIntValue(
    typeaheadParams,
    Constants.TYPEAHEAD_PARAMS.LIMIT_SPECIALTIES,
    Constants.TYPEAHEAD_PARAMS.LIMIT_SPECIALTIES_DEFAULT
  );
  const limitOrg = getIntValue(
    typeaheadParams,
    Constants.TYPEAHEAD_PARAMS.LIMIT_ORG,
    Constants.TYPEAHEAD_PARAMS.LIMIT_ORG_DEFAULT
  );
  const limitPrn = getIntValue(
    typeaheadParams,
    Constants.TYPEAHEAD_PARAMS.LIMIT_PRACTITIONER,
    Constants.TYPEAHEAD_PARAMS.LIMIT_PRACTITIONER_DEFAULT
  );
  const radius = getIntValue(
    typeaheadParams,
    Constants.TYPEAHEAD_PARAMS.RADIUS,
    Constants.TYPEAHEAD_PARAMS.RADIUS_DEFAULT
  );

  const { navigate } = useRouter();
  const { setTypeaheadSearchStore, search, searchTerm, zipcode } =
    useTypeaheadStore(
      useShallow((state: TypeaheadState) => ({
        setTypeaheadSearchStore: state.setTypeaheadSearchStore,
        search: state.typeaheadSearchStore.search,
        searchTerm: state.typeaheadSearchStore.searchTerm,
        zipcode: state.typeaheadSearchStore.zipcode,
      }))
    );

  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { setCoverageType, setChipValue } = chipStore;
  const { setAnalyticsState } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      setAnalyticsState: state.setAnalyticsState,
    }))
  );
  const { setPCPSearchState, choosePCP, dependentSeqNbr } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      setPCPSearchState: state.setPCPSearchState,
      choosePCP: state.pcpSearchState.choosePCP,
      dependentSeqNbr: state.pcpSearchState.dependentSeqNbr,
    }))
  );
  const { setDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );

  const [isFocusedOnKeywordSearchInput, setIsFocusedOnKeywordSearchInput] =
    useState(false);
  const [isFocusedOnLocationSearch, setIsFocusedOnLocationSearch] =
    useState(false);
  const [isOpenMobileLocation, setIsOpenMobileLocation] = useState(false);
  const [keywordSearchTerm, setKeywordSearchterm] = useState(searchTerm || '');
  const { setIsCountySearch } = useContext(CountySearchContext);
  let searchText: string;
  const storedLocation = getGeoLocationFromStorage();
  const {
    id,
    name = '',
    latitude: geoLat,
    longitude: geoLong,
    zipCode,
    stateCode,
  } = storedLocation;
  const [locationInputVal, setLocationInputVal] = useState(name);
  const [headers, setHeaders] = useSessionStorage<any>(
    Constants.STORAGE_KEYS.SESSION.TYPE_AHEAD_HEADERS,
    {}
  );
  if (isCounty(id)) setIsCountySearch(true);
  const [results, setResults] = useState<any>('');
  const [combineRollUpCodes, setCombineRollUpCodes] = useState<any>('');
  const [aggregatedAoeCodes, setAggregatedAoeCodes] = useState<string[]>([]);
  const [bhProgramFacilityAgg, setBhProgramFacilityAgg] = useState<any>('');
  const [enter, setEnter] = useState(false);
  let typeaheadRequestData = {};

  const [locationSuggestions, setLocationSuggestions] = useState<object[]>();
  const [isLocationLoading, setIsLocationLoading] = useState(false);
  const [isKeywordSearchLoading, setIsKeywordSearchLoading] = useState(false);
  const [searchButtonResults, setSearchButtonResults] = useSessionStorage<any>(
    Constants.STORAGE_KEYS.SESSION.TYPE_AHEAD_SEARCH_RESULTS,
    []
  );
  const [autoCompleteCallCompleted, setAutoCompleteCallCompleted] =
    useState<any>(false);
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const enableTypeAheadWildCardSearch: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_TYPEAHEAD_WILDCARD_SEARCH
  );

  const enableRetriveTypeAheadData: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_RETRIVE_TYPEAHEAD_DATA
  );

  const commonSearchesLagoon: () => any = useLagoon('common-searches');
  const commonSearchesData = commonSearchesLagoon();
  const memberCoverages = getCoverageTypes(currentMember);
  const convertedMemberCoverages = memberCoverages?.map(
    (cov) => MemberCoverageTypeToUICoverageTypeConversion[cov]
  );
  const dentalCoverage = convertedMemberCoverages?.find((cov) => cov === 'D');
  const visionCoverage = convertedMemberCoverages?.find((cov) => cov === 'V');
  const behavioralCoverage = convertedMemberCoverages?.find(
    (cov) => cov === 'B'
  );
  const medicalCoverage = convertedMemberCoverages?.find((cov) => cov === 'M');
  const getCoverageType = [
    dentalCoverage,
    visionCoverage,
    medicalCoverage,
    behavioralCoverage,
  ];
  const [captureResults] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.AUTOCOMPLETE_CAPTURE_RESULTS,
  ]);

  const [enableUESSuggestionMatch] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_UES_SUGGESTION_MATCH,
  ]);

  const pseudoRollupCodeTable = useTypeaheadPseudoRollupTable();
  const specialityPreferenceTable = useLagoon(
    Constants.LAGOON_TABLE.SPECIALITY_PREFERENCE
  )().map((item) => ({
    rollupCode: item.rollupCode,
    specialityCode: item.specialityCode,
  }));

  const {
    suppressPreferredFacilityPLN,
    suppressPreferredFacilityDDP,
    suppressPreferredFacilityFSF,
  } = getSuppressFacilityFlags();

  const suppressEAPKeyword = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.EAP
  );

  const pseudoRollupCodeMap = new Map(
    pseudoRollupCodeTable.map(({ dntDescription, pseudoRollupCode }) => [
      dntDescription,
      pseudoRollupCode,
    ])
  );

  const keywordSuppressionFlags = [
    {
      pseudoVertical: pseudoRollupCodeMap.get(
        PseudoRollupCodeSearchDescription.PLN
      ),
      value: suppressPreferredFacilityPLN,
    },
    {
      pseudoVertical: pseudoRollupCodeMap.get(
        PseudoRollupCodeSearchDescription.OX_PLN
      ),
      value: suppressPreferredFacilityPLN,
    },
    {
      pseudoVertical: pseudoRollupCodeMap.get(
        PseudoRollupCodeSearchDescription.FSF
      ),
      value: suppressPreferredFacilityFSF,
    },
    {
      pseudoVertical: pseudoRollupCodeMap.get(
        PseudoRollupCodeSearchDescription.DDP_LAB
      ),
      value: suppressPreferredFacilityDDP,
    },
    {
      pseudoVertical: pseudoRollupCodeMap.get(
        PseudoRollupCodeSearchDescription.DDP_RAD
      ),
      value: suppressPreferredFacilityDDP,
    },
    {
      suggestion: UesSuggestions.EAP,
      value: suppressEAPKeyword,
    },
  ];
  const allConfigs = useLagoon('config')();
  const excludeNatAncForNetworkIds = allConfigs?.find(
    (row) => row.key === 'EXCLUDE_NAT_ANC_FOR_NETWORK_IDS'
  )?.value;

  const searchInputOptionLocation = 'search-input-option-list';

  const [cursor, setCursor] = useState(-1);

  const searchLabelText = find(data, {
    key: ConstantsLagoon.SEARCH_LABEL,
  });

  const isShowingKeywordSearchDropdown = isFocusedOnKeywordSearchInput;
  const isShowingLocationDropdown = isFocusedOnLocationSearch && !mobileScreen;

  const { claimSystemTypeCode, eligibilitySystemTypeCode } =
    getClaimOrEligibilitySystemTypeCode(currentMember, coverageType);

  const membershipCategory = getMemberShipCategory(currentMember, featureFlags);

  const setKeywordSearchInputFocused = () => {
    setIsFocusedOnKeywordSearchInput(true);
    setIsFocusedOnLocationSearch(false);
  };

  const setKeywordSearchInputBlur = () => {
    setIsFocusedOnKeywordSearchInput(false);
  };

  const setLocationInputBlur = () => {
    setIsFocusedOnLocationSearch(false);
  };

  useEffect(() => {
    !isFocusedOnLocationSearch && checkForEmptyLocation();
  }, [isFocusedOnLocationSearch]);

  const handleKeywordSearchInputFocus = (value) => {
    setKeywordSearchInputFocused();
    setCursor(-1);
    setEnter(false);
    setResults([]);
    setAutoCompleteCallCompleted(false);
    handleKeywordSearchInputChange(value);
  };

  const handleCloseLocationDropdown = () => {
    setKeywordSearchInputBlur();
    setLocationInputBlur();
  };

  const handleCloseLocationDrawer = () => {
    setKeywordSearchInputBlur();
    setLocationInputBlur();
  };

  const setLocationInputFocused = () => {
    setIsFocusedOnLocationSearch(true);
    setIsFocusedOnKeywordSearchInput(false);
  };

  const handleLocationSearchInputFocus = () => {
    setLocationInputFocused();
    setCursor(-1);
  };

  const handleLocationSearchTextChange = (value: string) => {
    setCursor(-1);
    setIsFocusedOnLocationSearch(true);
    setLocationInputVal(value);
  };
  const [, SendEventCapture] = useEventCapture({
    onCompleted: (result) => result,
    onError: () => {},
  });
  const handleEventCapture = async (
    typeAheadSource,
    typeAheadFilterDataResponse,
    typeAheadOpperation,
    typeAheadCorrelationId,
    typeAheadRequestForEventCapture
  ) => {
    SendEventCapture({
      variables: {
        source: typeAheadSource,
        results: JSON.stringify(
          getTypeAheadResultTrackingData(typeAheadFilterDataResponse)
        ),
        operationName: typeAheadOpperation,
        correlationId: typeAheadCorrelationId,
        request: JSON.stringify(typeAheadRequestForEventCapture),
      },
    });
  };
  const [typeAheadData, GetSearchSuggestions] = useAutoCompleteQuery({
    onCompleted: (response) => {
      setIsKeywordSearchLoading(false);
      const requestData = JSON.parse(response?.config?.data);
      const suggestions =
        response?.data?.autoComplete?.lang_provider?.langProvider;
      const concatenatedRollupCodes =
        response?.data?.autoComplete?.lang_provider?.specialityRollUpCodes;
      const concatenatedAoeCodes =
        response?.data?.autoComplete?.lang_provider?.aoeCodes;
      const providers =
        response?.data?.autoComplete?.practitioners_uhc?.provData;
      const facilities =
        response?.data?.autoComplete?.organizations_uhc?.orgData;
      setSearchButtonResults(suggestions?.concat(providers).concat(facilities));
      var suggestionResults;
      if (hideDisabledTypeahead) {
        suggestionResults = excludeDisabledResults(
          convertedMemberCoverages,
          suggestions
        );
      } else {
        suggestionResults = suggestions;
      }

      const parsedResults = setTypeAheadResults(
        suggestionResults,
        providers,
        facilities,
        network
      );
      const correlationId = response?.headers[OPTUM_CORRELATION_ID_HEADER];
      const concatenatedBhProgramFacility =
        response?.data?.autoComplete?.lang_provider?.bhProgramFacilityAgg;

      setHeaders({
        correlationId,
      });
      setCombineRollUpCodes(concatenatedRollupCodes);
      setAggregatedAoeCodes(concatenatedAoeCodes);
      setBhProgramFacilityAgg(concatenatedBhProgramFacility);
      setResults(parsedResults);
      setAutoCompleteCallCompleted(true);
      adobeImpressionTrackEvent({
        searchTerm: searchText,
        type: 'typeahead search',
        message:
          parsedResults.length === 0
            ? 'no results found for your search'
            : 'results found for your search',
        customAttributesBlock: {
          correlationId,
          searchLocation: getSearchLocation(storedLocation),
        },
      });

      const filterData = response?.data?.autoComplete;

      if (captureResults) {
        handleEventCapture(
          'UES',
          filterData,
          'typeahead',
          correlationId,
          requestData?.variables
        );
      }
    },
    onError: () => {
      setResults(null);
    },
  });

  const handleKeywordSearchInputChange = useCallback(
    debounce(async ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setKeywordSearchterm(value.trim());
      setEnter(false);
      searchText = value.trim();
      setCursor(-1);
      setResults([]);
      if (value.replace(/\s/g, '').length > 1 && /[A-Za-z0-9]/.test(value)) {
        network = getMemberCoverage(currentMember, featureFlags);
        const useMedicalNewRollUpCodes: boolean = getFeatureFlag(
          featureFlags,
          ConstantsLagoon.FEATURE_FLAGS.USE_MEDICAL_NEW_ROLLUP_CODES
        );
        const useBHNewRollUpCodes: boolean = getFeatureFlag(
          featureFlags,
          ConstantsLagoon.FEATURE_FLAGS.USE_BH_NEW_ROLLUP_CODES
        );
        const useVisionNewRollUpCodes: boolean = getFeatureFlag(
          featureFlags,
          ConstantsLagoon.FEATURE_FLAGS.USE_VISION_NEW_ROLLUP_CODES
        );
        const useDentalNewRollUpCodes: boolean = getFeatureFlag(
          featureFlags,
          ConstantsLagoon.FEATURE_FLAGS.USE_DENTAL_NEW_ROLLUP_CODES
        );
        const useAutocompleteRollUpCodeNameSearch: boolean = getFeatureFlag(
          featureFlags,
          ConstantsLagoon.FEATURE_FLAGS.USE_UES_ROLLUP_CODE_FOR_NAME_SEARCH
        );
        const useBHVirtualCarePseudoRollupCodeSearch: boolean = getFeatureFlag(
          featureFlags,
          ConstantsLagoon.FEATURE_FLAGS.BEHAVIORAL_HEALTH_VIRTUAL_CARE
        );

        const useOxfordPlnFlag: boolean = getFeatureFlag(
          featureFlags,
          ConstantsLagoon.FEATURE_FLAGS.OXFORD_PLN
        );
        const useEnableBhFacilitiesProgramSearchFlag: boolean = getFeatureFlag(
          featureFlags,
          ConstantsLagoon.FEATURE_FLAGS.ENABLE_BH_FACILITIES_PROGRAM_SEARCH
        );
        const includeAoeOnlySuggestions: boolean = getFeatureFlag(
          featureFlags,
          ConstantsLagoon.FEATURE_FLAGS.ENABLE_BH_AOE_TYPEAHEAD
        );
        typeaheadRequestData = {
          query: encodeURIComponent(value.trim()),
          network,
          latitude: geoLat,
          longitude: geoLong,
          lang: language,
          limitSpl,
          limitOrg,
          limitPrn,
          radius,
          membershipCategory,
          claimSystemTypeCode,
          eligibilitySystemTypeCode,
          pseudoRollupCodeTable,
          keywordSuppressionFlags,
          useMedicalNewRollUpCodes,
          useBHNewRollUpCodes,
          useVisionNewRollUpCodes,
          useDentalNewRollUpCodes,
          useAutocompleteRollUpCodeNameSearch,
          useBHVirtualCarePseudoRollupCodeSearch,
          useOxfordPlnFlag,
          useEnableBhFacilitiesProgramSearchFlag,
          includeAoeOnlySuggestions,
          excludeNatAncForNetworkIds: getExcludeNatAncForNetworkIds(
            excludeNatAncForNetworkIds
          ),
          domain: getDomainForAutoComplete(currentMember),
        };
        GetSearchSuggestions({
          variables: typeaheadRequestData,
        });
      }
    }, 400),
    [geoLat, geoLong, currentMember]
  );

  const checkForEmptyLocation = (): void => {
    if (!locationInputVal) {
      const storedLocation = getGeoLocationFromStorage();
      const { name } = storedLocation;
      setLocationInputVal(name);
    }
  };

  const isShowingLocationDrawer = useMemo(
    () => isFocusedOnLocationSearch && mobileScreen,
    [isFocusedOnLocationSearch, mobileScreen]
  );

  const getTypeaheadData = () =>
    cursor === -1 ? searchButtonResults?.[0] : searchButtonResults?.[cursor];

  const handleKeywordSuggestionKeyDown = (ev: {
    key: string;
    preventDefault: () => void;
    target: { value: string };
  }) => {
    handleKeywordSuggestionKeyDownHelper(
      ev,
      specialityPreferenceTable,
      enableTypeAheadWildCardSearch,
      enableRetriveTypeAheadData,
      {
        keywordSearchTerm,
        commonSearchesData,
        isKeywordSearchLoading,
        searchButtonResults,
        setEnter,
        cursor,
        results,
        combineRollUpCodes,
        aggregatedAoeCodes,
        bhProgramFacilityAgg,
        store: {
          chipStore,
          setTypeaheadSearchStore,
          setAnalyticsState,
          setPCPSearchState,
          setChipValue,
          setCoverageType,
          setDetailsStore,
        },
        navigate,
        getTypeaheadData,
        headers,
        setCursor,
        convertTypeaheadProviderIdAndType,
        setIsFocusedOnKeywordSearchInput,
        searchInputOptionLocation,
        blurSearchInput,
        choosePCP,
        dependentSeqNbr,
        getCoverageType,
        enableUESSuggestionMatch,
        currentMember,
      }
    );
  };

  const searchInputRef = useRef<HTMLInputElement>(null);
  const blurSearchInput = () => {
    searchInputRef?.current?.blur();
  };

  useEffect(() => {
    if (searchInputRef.current && !isGuestUser) {
      searchInputRef.current.value = '';
    }
  }, [currentMember]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutsideInput = wrapperRef.current
        ? !wrapperRef.current.contains(event.target)
        : true;
      if (isOutsideInput && !isShowingLocationDrawer) {
        setKeywordSearchInputBlur();
        setLocationInputBlur();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleClickOutside);
    };
  }, [wrapperRef, isShowingLocationDrawer]);

  useEffect(() => {
    if (zipcode) onZipcodeChangeFromQueryParam(zipcode);
  }, [zipcode]);

  const onZipcodeChangeFromQueryParam = async (zipCode?: string) => {
    const { data } = await getLocation({
      variables: {
        address: zipCode,
        countySearchEnabled: true,
      },
    });
    if (data) {
      const locationData = data.location.features[0];
      const [selectedLong, selectedLat] = locationData.center || [];

      if (isCounty(id)) setIsCountySearch(true);
      else setIsCountySearch(false);

      setGeoLocationToStorage({
        id: locationData.id,
        name: locationData.place_name,
        longitude: selectedLong,
        latitude: selectedLat,
        stateCode: locationData.stateCode,
        zipCode: locationData.zipCode,
      });
    }
  };

  const hideDisabledTypeahead = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY.HIDE_DISABLED_TYPEAHEAD
  );

  return (
    <React.Fragment>
      {mobileScreen && isShowingKeywordSearchDropdown && <DarkeningOverlay />}
      <PositioningBox
        css={{
          height: chipsToTabsEnabled && !isMixedResultsPage ? '40px' : '62px',
        }}
        ref={wrapperRef}
      >
        {mobileScreen && showSearchInputBackButton ? (
          <Breadcrumb
            breadcrumbs={breadcrumbs}
            showBreadcrumbAtMediumScreenSize={
              showResultsPageForMap ? false : true
            }
          />
        ) : null}
        <InputBox
          hasShadow={isShowingKeywordSearchDropdown}
          isSharpBottomRightCorner={isShowingLocationDropdown}
          mobileScreen={mobileScreen}
        >
          <SearchPlacement
            choosePCPHeader={choosePCPHeader}
            commonSearchesData={commonSearchesData}
            dentalCoverage={dentalCoverage}
            handleKeywordSearchInputChange={handleKeywordSearchInputChange}
            handleKeywordSearchInputFocus={handleKeywordSearchInputFocus}
            handleKeywordSuggestionKeyDown={handleKeywordSuggestionKeyDown}
            isFocusedOnKeywordSearchInput={isFocusedOnKeywordSearchInput}
            keywordSearchInputId={keywordSearchInputId}
            keywordSearchTerm={keywordSearchTerm}
            search={search}
            searchButtonResults={searchButtonResults}
            searchInputRef={searchInputRef}
            searchLabelText={searchLabelText}
            setIsKeywordSearchLoading={setIsKeywordSearchLoading}
            setResults={setResults}
            setSearchButtonResults={setSearchButtonResults}
            visionCoverage={visionCoverage}
          />

          {isShowingKeywordSearchDropdown && (
            <KeywordSearchDropdown
              activeSuggestion={cursor}
              aggregatedAoeCodes={aggregatedAoeCodes}
              autoCompleteCallCompleted={autoCompleteCallCompleted}
              bhProgramFacilityAgg={bhProgramFacilityAgg}
              blurKeywordSearchInput={blurSearchInput}
              checkForEmptyLocation={checkForEmptyLocation}
              combinedRollupCodes={combineRollUpCodes}
              dentalCoverage={dentalCoverage}
              enableUESSuggestionMatch={enableUESSuggestionMatch}
              enter={enter}
              getCoverageType={getCoverageType}
              headers={headers}
              isKeywordSearchLoading={isKeywordSearchLoading}
              isLoading={typeAheadData?.isLoading}
              keywordSearchTerm={keywordSearchTerm}
              medicalCoverage={medicalCoverage}
              memberDDP={currentMember?.ddpCode}
              setIsFocusedOnKeywordSearchInput={
                setIsFocusedOnKeywordSearchInput
              }
              typeAheadSuggestions={results}
              visionCoverage={visionCoverage}
            />
          )}
        </InputBox>

        <LocationInput
          aggregatedAoeCodes={aggregatedAoeCodes}
          bhProgramFacilityAgg={bhProgramFacilityAgg}
          checkForEmptyLocation={checkForEmptyLocation}
          combinedRollupCodes={combineRollUpCodes}
          cursor={cursor}
          data-testid="location-search-input"
          enableTypeAheadWildCardSearch={enableTypeAheadWildCardSearch}
          enableUESSuggestionMatch={enableUESSuggestionMatch}
          handleCloseLocationDrawer={handleCloseLocationDrawer}
          handleCloseLocationDropdown={handleCloseLocationDropdown}
          headers={headers}
          isFocusedOnKeywordSearchInput={isFocusedOnKeywordSearchInput}
          isOpenMobileLocation={isOpenMobileLocation}
          isShowingLocationDrawer={isShowingLocationDrawer}
          isShowingLocationDropdown={isShowingLocationDropdown}
          locationValue={locationInputVal}
          memberLocation={memberLocation}
          onLocationSearchInputFocus={handleLocationSearchInputFocus}
          onLocationSearchInputTextChange={handleLocationSearchTextChange}
          placeHolderValue={name}
          searchTerm={keywordSearchTerm}
          setCursor={setCursor}
          setIsFocusedOnKeywordSearchInput={setIsFocusedOnKeywordSearchInput}
          setIsLocationLoading={setIsLocationLoading}
          setIsOpenMobileLocation={setIsOpenMobileLocation}
          setLocationInputVal={setLocationInputVal}
          setLocationSuggestions={setLocationSuggestions}
          specialityPreferenceTable={specialityPreferenceTable}
          typeAheadSuggestions={results}
        />

        {isShowingLocationDropdown && (
          <LocationDropdown
            activeSuggestion={cursor}
            data-testid="location-search-bar-dropdown"
            isLocationLoading={isLocationLoading}
            locationInputVal={locationInputVal}
            locationSuggestions={locationSuggestions}
            onClose={handleCloseLocationDropdown}
            setLocationInputVal={setLocationInputVal}
            setSelectedLocation={(targetLocation) => {
              const {
                id: locationId,
                center = [],
                place_name: placeName,
                stateCode: targetStateCode,
                zipCode: targetZipCode,
              } = targetLocation;
              const [selectedLong, selectedLat] = center;
              if (isCounty(locationId)) setIsCountySearch(true);
              else setIsCountySearch(false);

              setGeoLocationToStorage({
                id: locationId,
                name: placeName,
                longitude: selectedLong,
                latitude: selectedLat,
                stateCode: targetStateCode,
                zipCode: targetZipCode,
              });
            }}
          />
        )}

        <ChangeLocationDrawer
          isOpen={isShowingLocationDrawer}
          onClose={handleCloseLocationDrawer}
          position="bottom"
          size="$lg"
          title={t('Change Location')}
        >
          <ChangeLocationForm
            data-testid="change-location-form"
            onLocationSelect={handleCloseLocationDrawer}
          />
        </ChangeLocationDrawer>
      </PositioningBox>
      {mobileScreen && (
        <MobileSetLocation
          locationInputVal={locationInputVal}
          setIsOpenMobileLocation={setIsOpenMobileLocation}
          stateCode={stateCode}
          zipCode={zipCode}
        />
      )}
    </React.Fragment>
  );
};
