import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useVisuallyHidden } from '@abyss/web/hooks/useVisuallyHidden';
import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import translationsEnJson from '../../../public/locale/en/translation.json';
import { SearchFilterContext } from '../../context/SearchFilterContext';
import { useCoverageType } from '../../hooks/useCoverageType';
import { useCurrentMemberIsPreEffective } from '../../hooks/useCurrentMemberIsPreEffective';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useLagoon } from '../../hooks/useLagoon';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { getCurrentMember } from '../../utils/user.utils';
import { adobeSearchFilterEvent } from '../AdobeTagging/adobeSearchFilterEvent';
import {
  Constants,
  ReverseCoverageTypesCodes,
  SortOptionValues,
} from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { mobileOnly, phoneOnly } from '../ConstantsStyles';
import { Distance } from '../ProviderDetailTabs/ProviderLocationsDetails/Distance';
import {
  getFormattedFilters,
  getSearchTerm,
} from '../Utils/adobeTrackUtils/adobeTrackUtils';
import { getFeatureFlag } from '../Utils/getFeatureFlag';
import { InfoModal } from './InfoModal';
import { showUpcomingAvailabilityOption } from './ResultSortUtils';
import { SortBy } from './SortBy';
import { SortByMobile } from './SortByMobile';

export const SortContainer = styled('div', {
  '.abyss-radio-root ~div': {
    '&:hover': {
      backgroundColor: '$tint2',
    },
  },
});

export const ToggleSortButton = styled('div', {
  dynamic: ({ cssProps: { enableMapViewEnhancements } }) => ({
    backgroundColor: enableMapViewEnhancements ? 'white' : null,
  }),
  height: '42px',
  fontSize: '16px',
  padding: '$sm $lg $sm $md',
  border: '1px solid $gray4',
  borderRadius: '24px',
  display: 'flex',
  alignItems: 'center',
  gap: '$xs',
});

type Props = {
  sectionType?: string;
  isMapView?: boolean;
};

const { PROVIDER, FACILITY } = Constants.RESULT_SECTION;

const filterSortLabel = (searchFilters, t) =>
  searchFilters?.SortBy?.label
    ? t(searchFilters?.SortBy?.label)
    : t('SORT_BUTTONS.SORT');

const findBestMatchContent = (data, key) => find(data, { key });
const handleVisionBestMatchContent = (sectionType, data) => {
  const keys = {
    [FACILITY]:
      ConstantsLagoon.CONTENT_FILTERS
        .BEST_MATCH_POPOVER_CONTENT_VISION_FACILITY,
    [PROVIDER]:
      ConstantsLagoon.CONTENT_FILTERS
        .BEST_MATCH_POPOVER_CONTENT_VISION_PROVIDER,
  };
  return findBestMatchContent(data, keys[sectionType]);
};
const handleMedicalBestMatchContent = (sectionType, data) => {
  const keys = {
    [FACILITY]: ConstantsLagoon.CONTENT_FILTERS.BEST_MATCH_MEDICAL_FACILITY,
    [Constants.RESULT_SECTION.PROVIDER_GROUPS]:
      ConstantsLagoon.CONTENT_FILTERS.BEST_MATCH_MEDICAL_PROVIDER_GROUP,
    [PROVIDER]: ConstantsLagoon.CONTENT_FILTERS.BEST_MATCH_MEDICAL_PROVIDER,
  };
  return findBestMatchContent(data, keys[sectionType]);
};
const handleBehavioralHealthBestMatchContent = (sectionType, data) => {
  const keys = {
    [FACILITY]: ConstantsLagoon.CONTENT_FILTERS.BEST_MATCH_BH_FACILITY,
    [Constants.RESULT_SECTION.PROVIDER_GROUPS]:
      ConstantsLagoon.CONTENT_FILTERS.BEST_MATCH_BH_PROVIDER_GROUP,
    [PROVIDER]: ConstantsLagoon.CONTENT_FILTERS.BEST_MATCH_BH_PROVIDER,
  };
  return findBestMatchContent(data, keys[sectionType]);
};

const bestMatchContent = (coverageType, sectionType, data, isCns) => {
  if (coverageType === ReverseCoverageTypesCodes.VISION) {
    return handleVisionBestMatchContent(sectionType, data);
  }
  if (isCns) {
    switch (coverageType) {
      case ReverseCoverageTypesCodes.MEDICAL:
        return handleMedicalBestMatchContent(sectionType, data);
        break;
      case ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']:
        return handleBehavioralHealthBestMatchContent(sectionType, data);
        break;
      case ReverseCoverageTypesCodes.DENTAL:
        if (sectionType === PROVIDER) {
          return find(data, {
            key: ConstantsLagoon.CONTENT_FILTERS.BEST_MATCH_DENTAL_PROVIDER,
          });
        }
        break;
    }
  }
  return findBestMatchContent(
    data,
    ConstantsLagoon.CONTENT_FILTERS.BEST_MATCH_POPOVER_CONTENT
  );
};

export const ResultSort = ({ sectionType, isMapView }: Props) => {
  const { t } = useTranslation();
  const typeaheadState = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      search: state.typeaheadSearchStore.search,
      searchMethod: state.typeaheadSearchStore.searchMethod,
      searchTerm: state.typeaheadSearchStore.searchTerm,
    }))
  );
  const [enableMapViewEnhancements] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LIST_VIEW_MAP_ENHANCEMENTS,
  ]);
  const featureFlags = useLagoon('feature-flags')();
  const featureInclusion = useLagoon('feature-inclusion')();
  const currentMember = getCurrentMember();
  const isCns = currentMember?.isCnSMember;
  const showCustomSort = getFeatureFlag(
    featureFlags,
    'RESULT_PAGE_CUSTOM_SORT'
  );

  const showUpcomingAvailability = getFeatureFlag(
    featureFlags,
    'ENABLE_SORT_UPCOMING_AVAILABILITY'
  );

  const upcomingAvailabilityEligible = isCns
    ? featureInclusion
        ?.find((row) => row?.dntFeature === 'DocASAP')
        ?.policyIds.includes(currentMember?.policyNumber)
    : true;

  const isPreEffective = useCurrentMemberIsPreEffective();

  const data = useLagoon('ui-messaging')();

  const coverageType = useCoverageType();
  const content = bestMatchContent(
    coverageType,
    sectionType,
    data,
    isCns
  )?.message;

  const options = {
    BEST_MATCH: t('SORT_OPTIONS.PREFERRED_PROVIDERS'),
    CLOSEST: t('SORT_OPTIONS.CLOSEST'),
    HIGHEST_RATING: t('SORT_OPTIONS.HIGHEST_RATING'),
  };
  const customSort = {
    UPCOMING_AVAILABILITY: t('SORT_OPTIONS.UPCOMING_AVAILABILITY'),
    TIER_PROV: t('SORT_OPTIONS.TIER_PROV'),
    ACO_PROV: t('SORT_OPTIONS.ACO_PROV'),
    UHPD_PROV: t('SORT_OPTIONS.UHPD_PROV'),
    COST_EFF_RATING: t('SORT_OPTIONS.COST_EFF_RATING'),
    ALPHA_A_Z: t('SORT_OPTIONS.ALPHA_A_Z'),
    ALPHA_Z_A: t('SORT_OPTIONS.ALPHA_Z_A'),
  };

  const { searchFilters, setSearchFilters } = useContext(SearchFilterContext);
  const [isOpen, setIsOpen] = useState(false);
  const mobileScreen = useMediaQuery(
    enableMapViewEnhancements ? mobileOnly : phoneOnly
  );
  const { visuallyHiddenProps } = useVisuallyHidden();

  const onApply = (value: string) => {
    const selectedFilter = {
      SortBy: {
        label: options[value] || customSort[value],
        value,
        hide: true,
        dnt_label: translationsEnJson.SORT_OPTIONS[value],
      },
    };
    setSearchFilters({
      ...searchFilters,
      ...selectedFilter,
    });

    adobeSearchFilterEvent({
      type: sectionType,
      term: getSearchTerm(typeaheadState),
      filters: getFormattedFilters(selectedFilter),
    });
  };

  const sort = {
    label: (
      <React.Fragment>
        <ToggleSortButton
          cssProps={{ enableMapViewEnhancements }}
          data-auto-testid="toggle-sort-button"
          data-testid="toggle-sort-button"
        >
          {!isMapView && (
            <IconMaterial
              color={mobileScreen ? '$primary1' : '$descriptionBlack'}
              icon="swap_vert"
            />
          )}
          <Text fontWeight="$semibold" size="$md">
            {filterSortLabel(searchFilters, t)}
          </Text>
          <IconMaterial
            color={mobileScreen ? '$primary1' : '$descriptionBlack'}
            icon="keyboard_arrow_down"
          />
        </ToggleSortButton>
      </React.Fragment>
    ),
    title: t('SORT_BUTTONS.SORT_RESULT'),
    name: t('SORT_BUTTONS.SORT'),
    icon: 'sort',
    model: 'SortBy',
    type: 'radio',
    options: [
      {
        label: (
          <React.Fragment>
            <Flex css={{ gap: '$xs' }}>
              <Text>{options.BEST_MATCH}</Text>
              <Distance
                ariaLabel={t(
                  'ACCESSIBILITY_LABELS.INFORMATION_ABOUT_PREFERRED_PROVIDERS'
                )}
                content={content}
                title={options.BEST_MATCH}
                widths={297}
              />
              <span {...visuallyHiddenProps}>
                {t('SORT_OPTIONS.BEST_MATCH_POPOVER_CONTENT')}
              </span>
            </Flex>
          </React.Fragment>
        ),
        value: Constants.PROVIDER_SEARCH_SORT_BY,
        dnt_label: translationsEnJson.SORT_OPTIONS.PREFERRED_PROVIDERS,
      },
      ...(coverageType === ReverseCoverageTypesCodes.VISION &&
      sectionType === PROVIDER
        ? [
            {
              label: options.CLOSEST,
              value: SortOptionValues.CLOSEST,
              dnt_label: translationsEnJson.SORT_OPTIONS.CLOSEST,
            },
            {
              label: options.HIGHEST_RATING,
              value: SortOptionValues.HIGHEST_RATING,
              dnt_label: translationsEnJson.SORT_OPTIONS.HIGHEST_RATING,
            },
          ]
        : [
            ...(sectionType === Constants.RESULT_SECTION.PROVIDER
              ? [
                  {
                    label: options.HIGHEST_RATING,
                    value: SortOptionValues.HIGHEST_RATING,
                    dnt_label: translationsEnJson.SORT_OPTIONS.HIGHEST_RATING,
                  },
                ]
              : []),
            {
              label: options.CLOSEST,
              value: SortOptionValues.CLOSEST,
              dnt_label: translationsEnJson.SORT_OPTIONS.CLOSEST,
            },
          ]),
      ...(showUpcomingAvailabilityOption(
        showUpcomingAvailability,
        isPreEffective,
        upcomingAvailabilityEligible
      )
        ? [
            ...(sectionType === PROVIDER &&
            (coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] ||
              coverageType === ReverseCoverageTypesCodes.MEDICAL)
              ? [
                  {
                    label: customSort.UPCOMING_AVAILABILITY,
                    value: SortOptionValues.UPCOMING_AVAILABILITY,
                    dnt_label:
                      translationsEnJson.SORT_OPTIONS.UPCOMING_AVAILABILITY,
                  },
                ]
              : []),
          ]
        : []),
      ...(showCustomSort
        ? [
            {
              label: customSort.TIER_PROV,
              value: SortOptionValues.TIER_PROV,
              dnt_label: translationsEnJson.SORT_OPTIONS.TIER_PROV,
            },
            {
              label: customSort.ACO_PROV,
              value: SortOptionValues.ACO_PROV,
              dnt_label: translationsEnJson.SORT_OPTIONS.ACO_PROV,
            },
            {
              label: customSort.UHPD_PROV,
              value: SortOptionValues.UHPD_PROV,
              dnt_label: translationsEnJson.SORT_OPTIONS.UHPD_PROV,
            },
            {
              label: customSort.COST_EFF_RATING,
              value: SortOptionValues.COST_EFF_RATING,
              dnt_label: translationsEnJson.SORT_OPTIONS.COST_EFF_RATING,
            },
          ]
        : []),
      ...(sectionType !== Constants.RESULT_SECTION.PROVIDER && showCustomSort
        ? [
            {
              label: customSort.ALPHA_A_Z,
              value: SortOptionValues.ALPHA_A_Z,
              dnt_label: translationsEnJson.SORT_OPTIONS.ALPHA_A_Z,
            },
            {
              label: customSort.ALPHA_Z_A,
              value: SortOptionValues.ALPHA_Z_A,
              dnt_label: translationsEnJson.SORT_OPTIONS.ALPHA_Z_A,
            },
          ]
        : []),
    ],
  };

  return (
    <SortContainer>
      {mobileScreen ? (
        <SortByMobile
          defaultValue={searchFilters?.SortBy?.value}
          icon={sort.icon}
          model={sort.model}
          name={sort.name}
          onApply={onApply}
          options={sort.options}
          size="$sm"
          title={sort.title}
        />
      ) : (
        <SortBy
          defaultValue={searchFilters?.SortBy?.value}
          label={sort.label}
          model={sort.model}
          onApply={onApply}
          options={sort.options}
          title={sort.title}
        />
      )}
      <InfoModal
        dataForAnalytics={{
          linkName: sort.title,
          linkLocation: `body:sort by`,
          modalName: sort.title,
        }}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </SortContainer>
  );
};
