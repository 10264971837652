import { CareCategories, CoverageTypesCodes } from '../common/Constants';
import { isCnsMember } from '../common/Utils/memberUtils/memberUtils';
import { getMemberHealthCoverageType } from './providerSearch.utils';
import { getMemberHasDesignatedNetwork } from './user.utils';

const validateBenefitEligibility = (
  benefitEligibility: string | undefined,
  memberVals: string | string[]
): boolean => {
  if (!benefitEligibility) {
    return true;
  }
  const requirementsAr = benefitEligibility.split(',');
  return (
    requirementsAr.filter((tabReq) => memberVals?.includes(tabReq.trim()))
      ?.length > 0
  );
};

const validatePlanPurchaseProvisions = (
  benefitPlanPurchaseProvisions: string | undefined,
  eapPlanPurchaseIds
) => {
  if (!benefitPlanPurchaseProvisions) {
    return true;
  }
  const requirementsAr = benefitPlanPurchaseProvisions?.split(',');
  return requirementsAr.some((e) => eapPlanPurchaseIds?.includes(e));
};

const isBenefitSuppressed = (
  benefitEligibility: string | undefined,
  memberVals: string
): boolean =>
  !!benefitEligibility
    ?.split(',')
    ?.map((x) => x.trim())
    ?.includes(memberVals);

const validateNumberOfVisits = (
  eapVisits: string,
  noOfVisits: string
): boolean => {
  if (!eapVisits) {
    return true;
  }
  if (!noOfVisits) {
    return false;
  }
  const pendingVisits = parseInt(noOfVisits);
  const visits = parseInt(eapVisits);
  if (isNaN(pendingVisits) || isNaN(visits)) {
    return false;
  }
  return pendingVisits >= visits;
};

const constructNumberOfVisits = (
  noOfVisits: string,
  benefitDesc: string
): string => {
  if (benefitDesc && benefitDesc.includes('[x]') && noOfVisits !== '0') {
    return benefitDesc.replaceAll('[x]', noOfVisits);
  }
  return benefitDesc;
};

export const getBenefitsForTab = (
  benefits,
  activeChip,
  currentMember,
  memberCoverages,
  memberMarkets,
  memberPolicy,
  memberPVRC,
  eapPlanPurchaseProvisions,
  numberOfVisits,
  memberHPBP
) => {
  const eapPlanPurchaseIds = eapPlanPurchaseProvisions?.map((e) => e?.id) || [];
  const isCNS = isCnsMember(currentMember?.lineOfBusiness);

  const checkBasedOnLob = (benefit) => {
    if (isCNS) {
      return benefit.groupId?.trim()
        ? validateBenefitEligibility(benefit.groupId?.trim(), memberPolicy)
        : true;
    }

    return (
      validateBenefitEligibility(benefit.coverageTypes, memberCoverages) &&
      validateBenefitEligibility(benefit.marketCodes, memberMarkets) &&
      (benefit.policyNumber?.trim() &&
      benefit.policyNumber?.trim().toLowerCase() !== 'all'
        ? validateBenefitEligibility(benefit.policyNumber, memberHPBP) ||
          validateBenefitEligibility(benefit.policyNumber?.trim(), memberPolicy)
        : true) &&
      (memberPVRC &&
      benefit.pvrcCodes?.trim() &&
      benefit.pvrcCodes?.trim().toLowerCase() !== 'all'
        ? validateBenefitEligibility(benefit.pvrcCodes, memberPVRC)
        : true) &&
      validatePlanPurchaseProvisions(
        benefit.planPurchaseProvisions,
        eapPlanPurchaseIds
      ) &&
      validateNumberOfVisits(benefit.eapVisits, numberOfVisits) &&
      !isBenefitSuppressed(benefit.suppressedPolicyIds, memberPolicy)
    );
  };

  return benefits
    .filter(
      (benefit) =>
        benefit.activeTabs
          ?.split(',')
          .find(
            (activeTab) =>
              activeTab?.trim().toLowerCase() === activeChip.toLowerCase()
          ) &&
        benefit.isActive &&
        checkBasedOnLob(benefit)
    )
    .map((benefit) => {
      if (!isCNS && benefit.eapVisits) {
        benefit.benefitDescription = constructNumberOfVisits(
          numberOfVisits,
          benefit.benefitDescription
        );
      }

      return benefit;
    });
};

export const suppressBHForPlans = (
  benefits,
  selectedChipValue,
  eligibility
) => {
  if (
    [CareCategories.BEHAVIORAL_HEALTH, CareCategories.ALL].includes(
      selectedChipValue
    )
  ) {
    const memberCoverage = getMemberHealthCoverageType(
      eligibility,
      CoverageTypesCodes.BEHAVIORAL_HEALTH
    );

    return benefits.filter(
      (benefit) =>
        !benefit.suppressedProductIds
          ?.split(',')
          ?.includes(memberCoverage?.productID)
    );
  }

  return benefits;
};

export const suppressVirtualCareBenefits = (
  benefits,
  isPreEffective,
  suppressVirtualCare,
  eligibility
) => {
  const memberHealthCoverage = getMemberHealthCoverageType(
    eligibility,
    CoverageTypesCodes.MEDICAL
  );
  return benefits.filter((item) => {
    const isVirtualCareBenefit = item?.linkUrl?.includes('virtualcare');

    if (!isVirtualCareBenefit) {
      return true;
    }

    const hasDVCN = getMemberHasDesignatedNetwork(memberHealthCoverage, 'DVCN');
    return !isPreEffective && !suppressVirtualCare && hasDVCN;
  });
};

export const ifpBenefitContent = (
  benefits,
  selectedChipValue,
  currentMember
) => {
  const { isKelseySeyboldPlan, isGatedUser } = currentMember;

  const filterBenefits = (benefits, isKelseySeyboldPlan) =>
    benefits?.filter((item) => {
      if (isKelseySeyboldPlan) {
        return item?.planType?.toLowerCase() === 'kelsey';
      } else if (!isKelseySeyboldPlan) {
        return (
          item?.planType == null ||
          item.planType.length === 0 ||
          item.planType?.toLowerCase() === 'non-kelsey'
        );
      }
      return false;
    });

  return filterBenefits(benefits, isKelseySeyboldPlan, isGatedUser);
};
